export const SkillItems = [
  {
    name: "Languages",
    items: [
      "Java",
      "Javascript",
      "Typescript",
      "Python",
      "HTML/CSS",
      "C",
      "C Sharp",
      "SQL",
    ],
    cName: "skill-container",
  },
  {
    name: "Frameworks",
    items: [
      "Node",
      "Spring Boot",
      "Nestjs",
      "React",
      "Angular",
      "Sass",
      "Flask",
      "Postman",
      "Django",
      "SocketIO",
    ],
    cName: "skill-container",
  },
  {
    name: "Tools/Other",
    items: [
      "Git",
      "Linux",
      "MongoDB",
      "PostgreSql",
      "MySql",
      "Redis",
      "Jira",
      "Tableau",
      "Nginx",
      "Docker",
      "AWS",
      "Hibernate",
      "Unity",
      "Figma",
      "Visual Studio Code",
      "Visual Studio",
      "Strapi",
    ],
    cName: "skill-container",
  },
];
